import { useCallback, useMemo } from "react"

/**
 * Enable logging for image loading in development.
 */
export const DEBUG_IMAGE_LOADING = false

/**
 * Interface representing the loading state of an image.
 */
interface ImageState {
  /**
   * Whether the image failed to load
   */
  imageError: boolean
  /**
   * Whether the image has successfully loaded
   */
  hasLoaded: boolean
  /**
   * Whether the image is currently loading
   */
  isLoading: boolean
  /**
   * Timestamp when image loading started, used to track load duration
   */
  loadStartTime: number
}

interface UseImageLoadHandlersProps {
  /**
   * The current state of the image loading process
   */
  state: ImageState
  /**
   * State setter function to update the image loading state
   */
  setState: React.Dispatch<React.SetStateAction<ImageState>>
  /**
   * Optional index if the image is part of a list, used for debugging and load tracking
   */
  index?: number
  /**
   * The URL of the image being loaded, used for debugging messages
   */
  imageUrl?: string
  /**
   * Optional callback fired when the image successfully loads.
   * Receives the index parameter if provided.
   */
  onLoad?: (index?: number) => void
}

/**
 * A custom hook that provides handlers for managing image loading states and events.
 *
 * This hook returns callback functions to handle the loading lifecycle of an image:
 * - handleLoadStart: Called when image loading begins
 * - handleLoad: Called when image successfully loads
 * - handleError: Called if image fails to load
 *
 * The handlers manage loading state, track load times, provide debug logging,
 * and call the optional onLoad callback when loading completes.
 *
 * @param props - Configuration object for the hook
 * @param props.state - Current image loading state
 * @param props.setState - Function to update the image loading state
 * @param props.index - Optional index if image is part of a list
 * @param props.imageUrl - URL of the image being loaded
 * @param props.onLoad - Optional callback fired on successful load
 * @returns Object containing the load handling functions
 */
export const useImageLoadHandlers = ({
  state,
  setState,
  index,
  imageUrl,
  onLoad,
}: UseImageLoadHandlersProps) => {
  const handleLoadStart = useCallback(() => {
    if (state.hasLoaded || state.isLoading) return

    setState((prev) => ({ ...prev, isLoading: true, loadStartTime: Date.now() }))
    if (DEBUG_IMAGE_LOADING) {
      console.log(`⏳ [${index}] Starting to load image: ${imageUrl}`)
    }
  }, [index, imageUrl, state.isLoading, state.hasLoaded])

  const handleLoad = useCallback(() => {
    if (state.hasLoaded) return

    const loadTime = Date.now() - state.loadStartTime
    if (DEBUG_IMAGE_LOADING) {
      console.log(`✅ [${index}] Image loaded in ${loadTime}ms: ${imageUrl}`)
    }
    setState((prev) => ({
      ...prev,
      hasLoaded: true,
      isLoading: false,
    }))
    onLoad?.(index)
  }, [index, imageUrl, onLoad, state.hasLoaded, state.loadStartTime])

  const handleError = useCallback(() => {
    const loadTime = Date.now() - state.loadStartTime
    console.error(`❌ [${index}] Failed to load image after ${loadTime}ms: ${imageUrl}`)
    setState((prev) => ({ ...prev, imageError: true, isLoading: false }))
  }, [index, imageUrl, state.loadStartTime])

  return useMemo(
    () => ({
      handleLoadStart,
      handleLoad,
      handleError,
    }),
    [handleLoadStart, handleLoad, handleError],
  )
}
