import { FC, useState, useMemo, memo } from "react"
import { Image, ImageProps, View } from "tamagui"
import { PlaceholderImage, PlaceholderImageProps } from "./PlaceholderImage"
import { getImageUrl } from "./utils/imageWithPlaceholderUtils"
import { useImageLoadHandlers } from "./hooks/useImageLoadHandlers"
import { StyleSheet } from "react-native"

/**
 * Props for the ImageWithPlaceholder component.
 */
export interface ImageWithPlaceholderProps {
  /**
   * Props to pass to the placeholder image shown during loading
   */
  placeholderProps: PlaceholderImageProps
  /**
   * Props to pass to the actual image component
   */
  imageProps: ImageProps
  /**
   * Optional index if used in a list, used for debugging and load tracking
   */
  index?: number
  /**
   * Optional callback fired when the image loads successfully
   */
  onLoad?: (index?: number) => void
  /**
   * Whether the previous image in a sequence has loaded. Used for sequential loading in lists.
   * Defaults to true if not provided
   */
  previousImageLoaded?: boolean
}

/**
 * A component that displays an image with a placeholder while loading.
 *
 * This component handles loading states and errors for images, showing a placeholder
 * until the image is fully loaded. It supports sequential loading of images in a list
 * through the previousImageLoaded prop.
 */
export const ImageWithPlaceholder: FC<ImageWithPlaceholderProps> = memo(
  ({ placeholderProps, imageProps, index, onLoad, previousImageLoaded = true }) => {
    const [state, setState] = useState({
      imageError: false,
      hasLoaded: false,
      isLoading: false,
      loadStartTime: 0,
    })

    const imageUrl = useMemo(() => getImageUrl(imageProps.source), [imageProps.source])

    // FastImage callback support
    const { handleLoadStart, handleLoad, handleError } = useImageLoadHandlers({
      state,
      setState,
      index,
      imageUrl,
      onLoad,
    })

    // Add cache control headers for web
    const enhancedImageProps = useMemo(() => {
      if (typeof imageProps.source === "object" && "uri" in imageProps.source) {
        return {
          ...imageProps,
          source: {
            ...imageProps.source,
            headers: {
              ...imageProps.source.headers,
              // 1 month cache
              "Cache-Control": "max-age=2592000",
            },
          },
        }
      }
      return imageProps
    }, [imageProps])

    // Stable styles object
    const imageStyles = useMemo(
      () =>
        StyleSheet.create({
          image: {
            position: state.hasLoaded && !state.imageError ? "relative" : "absolute",
            opacity: state.hasLoaded && !state.imageError ? 1 : 0,
            display: state.imageError ? "none" : "flex",
            width: placeholderProps.width,
            height: placeholderProps.height,
            borderRadius: 6,
            ...(imageProps.style as object),
          },
        }).image,
      [
        state.hasLoaded,
        state.imageError,
        placeholderProps.width,
        placeholderProps.height,
        imageProps.style,
      ],
    )

    return (
      <View borderRadius="$6" overflow="hidden" testID="placeholder-image">
        {(!state.hasLoaded || state.imageError) && <PlaceholderImage {...placeholderProps} />}

        {previousImageLoaded && (
          <Image
            {...enhancedImageProps}
            style={imageStyles}
            onLoadStart={handleLoadStart}
            onLoad={handleLoad}
            onError={handleError}
          />
        )}
      </View>
    )
  },
  (prevProps, nextProps) => {
    const prevUrl = getImageUrl(prevProps.imageProps.source)
    const nextUrl = getImageUrl(nextProps.imageProps.source)

    const areEqual =
      prevProps.previousImageLoaded === nextProps.previousImageLoaded &&
      prevProps.index === nextProps.index &&
      prevUrl === nextUrl

    return areEqual
  },
)
