import { FC } from "react"
import { View, isWeb, ViewProps } from "tamagui"
import { Icon } from "../icon/Icon"

export interface PlaceholderImageProps extends ViewProps {
  backgroundColor?: ViewProps["backgroundColor"]
  iconColor?: string
  height?: number
  width?: number
}

export const PlaceholderImage: FC<PlaceholderImageProps> = ({
  backgroundColor = "$spruce50",
  iconColor = "$spruce80",
  height = 500,
  width = 700,
  ...props
}) => (
  <View
    alignContent="center"
    alignItems="center"
    backgroundColor={backgroundColor as ViewProps["backgroundColor"]}
    justifyContent="center"
    height={height}
    width={width}
    borderRadius="$6"
    {...props}
  >
    <Icon
      icon="image-landscape"
      color={iconColor}
      hoveringColor={iconColor}
      activeColor={iconColor}
      {...(isWeb ? { style: { height: "50%", width: "60%" } } : { size: width * 0.42 })}
    />
  </View>
)
