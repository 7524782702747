import { ImageSourcePropType } from "react-native"

/**
 * Extracts the URL string from various image source types supported by React Native.
 *
 * @param source - The image source which can be:
 *   - undefined
 *   - A string URL
 *   - A local asset number reference
 *   - An array of image sources
 *   - An object with a uri property
 *
 * @returns The extracted URL string, or undefined if no valid URL could be extracted:
 *   - The original string for string URLs
 *   - A formatted string for local assets
 *   - A formatted string for source arrays
 *   - The uri property for source objects
 *   - undefined for invalid/unsupported sources
 */
export function getImageUrl(source: ImageSourcePropType | undefined): string | undefined {
  if (!source) return undefined
  if (typeof source === "string") return source
  if (typeof source === "number") return `[local asset ${source}]`
  if (Array.isArray(source)) return `[multiple sources: ${source.length}]`
  if (source && typeof source === "object" && "uri" in source) return source.uri
  return undefined
}
