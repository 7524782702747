import { FC } from "react"
import { ProgramsLandingPage } from "./ProgramsLandingPage"
import { H1, H2, ScrollView, useMedia, View } from "tamagui"
import { HealthyLogo } from "app/assets/images/HealthyLogo"
import { ProgramsList } from "./ProgramsList"
import { useAuth } from "@my/api"

export const ExploreProgramsPage: FC = () => {
  const { isAuthenticated } = useAuth()
  const { xxs } = useMedia()

  const loggedIn = (
    <ScrollView paddingHorizontal="$base">
      <View flex={1} maxWidth={1300}>
        <View
          alignContent="center"
          alignItems="center"
          flexDirection="row"
          justifyContent="flex-start"
          paddingVertical={24}
        >
          <HealthyLogo style={{ height: 44, width: 150 }} />
        </View>

        <View marginBottom="$sm">
          <H1 fontSize={xxs ? 32 : 39} fontWeight={700} testID="explore-programs-header">
            Explore Programs
          </H1>
          <H2
            fontSize={xxs ? "$5" : "$6"}
            marginVertical="$xs"
            fontWeight={400}
            testID="explore-programs-sub-header"
          >
            Add programs to customize your check-ins, get suggested conversations, and track what
            matters most to&nbsp;you.
          </H2>
        </View>

        <ProgramsList />
      </View>
    </ScrollView>
  )
  const loggedOut = <ProgramsLandingPage />
  return isAuthenticated ? loggedIn : loggedOut
}
